<template>
  <modal name="nonActualBids" transition="pop-out" class="nonActualBidsPopup--wrapper">
    <div class="nonActualBidsPopup modal">
      <div>
        <div class="nonActualBids__table">
          <div class="nonActualBids__table-header">
            <div class="nonActualBids__table-cell nonActualBids__table-cell--date">Creation date</div>
            <div v-if="isAdmin" class="nonActualBids__table-cell">Flyer</div>
            <div class="nonActualBids__table-cell">Bid</div>
            <div class="nonActualBids__table-cell">Canceled by</div>
            <div class="nonActualBids__table-cell">Cancellation date</div>
            <div class="nonActualBids__table-cell">Willing To Split With Group</div>
          </div>
          <div v-if="isNonActualBidListLoading" class="loading">Loading.....</div>

          <div v-else class="nonActualBids__table-body js-customScroll">
            <vue-scroll :ops="ops" v-if="nonActualBidList.length">
              <div v-for="insideItem of nonActualBidList"
                   :key="insideItem.id"
                   class="nonActualBids__table-row"
              >
                <div class="nonActualBids__table-row-inner">
                  <div class="nonActualBids__table-row-header">
                    <div class="nonActualBids__table-cell nonActualBids__table-cell--date">
                      <div class="nonActualBids__table-cell__mobile">Creation date</div>
                      <div class="nonActualBids__table-cell__content">{{moment(insideItem.flightDepartureDate).format('M/D/YYYY')}}</div>
                    </div>
                    <div v-if="isAdmin" class="nonActualBids__table-cell">
                      <div class="nonActualBids__table-cell__mobile">Flyer</div>
                      <div class="nonActualBids__table-cell__content">{{ insideItem.name }}</div>
                    </div>
                    <div class="nonActualBids__table-cell">
                      <div class="nonActualBids__table-cell__mobile">BID</div>
                      <div class="nonActualBids__table-cell__content">{{insideItem.bidValue.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}}</div>
                    </div>
                    <div class="nonActualBids__table-cell">
                      <div class="nonActualBids__table-cell__mobile">Canceled by</div>
                      <div class="nonActualBids__table-cell__content">{{insideItem.canceledBy}}</div>
                    </div>
                    <div class="nonActualBids__table-cell nonActualBids__table-cell--date">
                      <div class="nonActualBids__table-cell__mobile">Cancellation date</div>
                      <div class="nonActualBids__table-cell__content">{{insideItem.canceledOn && moment(insideItem.canceledOn).format('M/D/YYYY')}}</div>
                    </div>
                    <div class="nonActualBids__table-cell">
                      <div class="nonActualBids__table-cell__mobile">Willing To Split With Group</div>
                      <div class="nonActualBids__table-cell__content">{{ insideItem.willingToSplitWithGroup ? 'Yes' : 'No' }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </vue-scroll>
            <div v-else class="emptyList">Nothing found</div>
          </div>
        </div>
      </div>
      <button type="button" class="modal__close" @click="this.hide">
        <SvgIcon name="close-white"/>
      </button>
    </div>
  </modal>
</template>

<script>
import './NonActualBidsPopup.styl'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import moment from 'moment'

export default {
  name: 'NonActualBidsPopup',
  props: ['isAdmin'],
  data: () => ({
    ops: {
      rail: {
        size: '3px',
      },
      bar: {
        size: '3px',
        background: 'rgba(0,0,0,0.1)',
      },
    },
  }),
  computed: {
    nonActualBidList () {
      return this.$store.state.adminBids.nonActualBidList
    },
    isNonActualBidListLoading () {
      return this.$store.state.adminBids.isNonActualBidListLoading
    },
  },
  methods: {
    moment,
    show () {
      this.$modal.show('nonActualBids')
    },
    hide () {
      this.$modal.hide('nonActualBids')
    },
  },
  created () {
    this.$parent.$on('showModal', this.show)
  },
  components: {
    SvgIcon,
  },
}

</script>
